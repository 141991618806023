import React from 'react';
import get from 'lodash/get';
import { StepSlider } from '../common/components';
import { getInternalUrl } from '../utils';

interface Props {
  data: any;
  lang: string;
}

class FacilitiesStepSliders extends React.Component<Props, any> {
  render() {
    const { data, lang } = this.props;

    const layoutType = get(
      data,
      'primary.layout',
      'primary on the left, secondary on the right'
    );
    const items: any[] = get(data, 'items', []);
    const layout =
      layoutType === 'primary on the left, secondary on the right'
        ? 'primary-left'
        : 'primary-right';
    // primary
    const primaryTitle = get(data, 'primary.primaryTitle', '');
    const primaryDescription = get(data, 'primary.primaryDescription.html', '');
    const primaryReadmoreUrl = get(
      data,
      'primary.primaryReadmoreUrl.document[0].data.url',
      false
    )
      ? getInternalUrl(
          get(data, 'primary.primaryReadmoreUrl.document[0].data.url', ''),
          lang
        )
      : get(data, 'primary.primaryReadmoreUrl.url', '');
    const primaryReadmoreText = get(data, 'primary.primaryReadmoreText', '');

    const primaryReadmoreUrlTarget = get(
      data,
      'primary.primaryReadmoreUrl.target',
      ''
    );

    // secondary
    const secondaryTitle = get(data, 'primary.secondaryTitle', '');
    const secondaryDescription = get(
      data,
      'primary.secondaryDescription.html',
      ''
    );
    const secondaryType = get(data, 'primary.secondaryType', 'card');
    const secondaryAdditional =
      secondaryType === 'card'
        ? get(data, 'primary.secondaryAdditionalDescription.html', '')
        : null;

    return (
      <StepSlider
        layout={layout}
        primary={{
          title: primaryTitle,
          description: primaryDescription,
          readmoreUrl: primaryReadmoreUrl,
          readmoreUrlTarget: primaryReadmoreUrlTarget,
          readmoreText: primaryReadmoreText,
        }}
        secondary={{
          type: secondaryType,
          title: secondaryTitle,
          description: secondaryDescription,
          additional: secondaryAdditional,
        }}
        images={items}
      />
    );
  }
}

export default FacilitiesStepSliders;

import { graphql } from 'gatsby';

import React, { Component } from 'react';
import get from 'lodash/get';

import Layout from '../layout/Layout';
import { PageHero, PageIntroduction } from '../common/components';

import FacilitiesStepSliders from './FacilitiesStepSliders';

interface Props {
  data: any;
  location: any;
}

class Facilities extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const body = get(data, 'page.data.body');

    const lang = get(data, 'page.lang', 'en-us');

    return (
      <Layout data={data} location={this.props.location}>
        {Array.isArray(body)
          && body.map((slice) => {
            switch (slice.__typename) {
              case 'PrismicFacilitiesBodyHero':
                return <PageHero key={slice.id + slice.__typename} data={slice} />;
              case 'PrismicFacilitiesBodyIntroduction':
                return <PageIntroduction key={slice.id + slice.__typename} data={slice} />;
              case 'PrismicFacilitiesBodyStepSliders':
                return (
                  <FacilitiesStepSliders
                    key={slice.id + slice.__typename}
                    lang={lang}
                    data={slice}
                  />
                );

              default:
                return null;
            }
          })}
      </Layout>
    );
  }
}

export default Facilities;

// Query metadata for the homepage
export const query = graphql`
  query FacilitiesQuery($slug: String!) {
    page: prismicFacilities(id: { eq: $slug }) {
      ...Facilities
    }
  }
`;
